import React from "react";
import styled from "styled-components";
import { getFlex, linkRouge, palette } from "../../styles/styles";
import Image from "../../ImageQuerys/CarbonImages";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Link } from "gatsby";

const InfoItem = (props) => {
  const { className, imageName, title, desc, blue, onRight } = props;
  const { t } = useTranslation("carbon");

  return (
    <section className={className}>
      {onRight ? null : (
        <div className="left">
          <Image className="img" imageName={imageName} alt="" />
        </div>
      )}
      <div className="right">
        <h2>{t(title)}</h2>
        <p>{t(desc)}</p>
        <div className="buttonLabel">
          <Link to="/contact" className="link">
            {t("learnMore")}
          </Link>
          <p className="blueLabel">{t(blue)}</p>
        </div>
        <Image className="mobileImg" imageName={imageName} alt="" />
      </div>
      {onRight ? (
        <div className="left">
          <Image className="img" imageName={imageName} alt="" />
        </div>
      ) : null}
    </section>
  );
};

export default styled(InfoItem)`
  padding: 4% 10% 4% 10%;
  display: grid;
  height: fit-content;
  grid-template-columns: 1fr 1.25fr;
  .left {
    ${getFlex("row", "ct", "ct")};
    @media (max-width: 768px) {
      display: none;
    }
  }
  .right {
    ${getFlex("column", "ct", "st")};
    row-gap: 1rem;
    padding: 0 8%;
    .redLabel {
      font-family: SofiaProSemiBoldAz, sans-serif;
      color: ${palette.red};
      font-size: 2rem;
    }
    h2 {
      text-align: start;
      width: 100%;
      font-size: 4.8rem;
      @media (max-width: 768px) {
        text-align: center;
      }
    }
    p {
      font-size: 2rem;
      line-height: 3.2rem;
      font-family: SofiaProLightAz, sans-serif;
      width: 100%;
    }
    .buttonLabel {
      margin-top: 4rem;
      display: grid;
      grid-template-columns: repeat(2, max-content);
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      grid-column-gap: 4rem;
      .link {
        ${linkRouge};
      }
      .blueLabel {
        width: fit-content;
        color: ${palette.blue};
        font-size: 2rem;
        font-family: SofiaProBoldAz, sans-serif;
        line-height: 2.4rem;
      }
    }
    .mobileImg {
      display: none;
    }
  }
  @media (max-width: 768px) {
    padding: 5% 8% 5% 8%;
    grid-template-columns: auto;
    justify-items: center;
    align-items: center;
    & > * {
      text-align: center;
    }
    .right {
      display: grid;
      grid-template-rows: auto;
      align-items: center;
      justify-items: center;
      grid-row-gap: 2rem;
      .mobileImg {
        display: flex !important;
        align-self: center;
      }
      .buttonLabel {
        ${getFlex("column", "ct", "ct")};
        grid-row-gap: 2rem;
        margin: 0;
      }
    }
  }
`;
