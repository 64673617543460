import React from "react";
import styled from "styled-components";
import Layout from "../comps/reusable/Layout";
import { graphql } from "gatsby";
import HeroSection from "../comps/reusable/HeroSection";
import SimpleParagraph from "../comps/reusable/SimpleParagraph";
import PreFooter from "../comps/reusable/Pre-footer";
import InfoItem from "../comps/carbon/InfoItem";

const Pledge = () => {

  return (
    <Layout>
      <HeroSection
        data={{
          imageName: "heroCarbon.png",
          hasButtons: false,
        }}
        nameSpace="carbon"
      />
      <SimpleParagraph nameSpace="carbon" />
      <InfoItem
        imageName="carbon1.png"
        title="infoFirstTitle"
        desc="infoFirstDesc"
        blue="infoFirstBlue"
      />
      <InfoItem
        imageName="carbon2.png"
        title="infoSecondTitle"
        desc="infoSecondDesc"
        blue="infoSecondBlue"
        onRight
      />
      <InfoItem
        imageName="carbon3.png"
        title="infoThirdTitle"
        desc="infoThirdDesc"
        blue="infoThirdBlue"
      />
      <PreFooter nameSpace="carbon" />
    </Layout>
  );
};

export default styled(Pledge)``;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
