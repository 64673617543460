import React from "react";
import styled from "styled-components";
import { palette } from "../../styles/styles";
import { useTranslation } from "gatsby-plugin-react-i18next";

const PreFooter = (props) => {
  const { className, nameSpace } = props;
  const { t } = useTranslation(nameSpace);

  return (
    <section className={className}>
      <h2>{t("preFooterTitle")}</h2>
      <p className="desc">{t("preFooterDesc")}</p>
      <p className="red">{t("preFooterRed")}</p>
    </section>
  );
};

export default styled(PreFooter)`
  padding: 8% 10%;
  background-color: ${palette.blue};
  height: fit-content;
  display: grid;
  grid-template-rows: auto;
  justify-items: center;
  align-items: center;
  grid-row-gap: 4rem;
  @media(max-width: 600px){
    padding: 10% 8%;
  }
  h2 {
    color: ${palette.whiteWhite};
    font-size: 4.8rem;
    width: 55%;
    text-align: center;
    line-height: 7.2rem;
    @media (max-width: 768px){
      width: 80%!important;
    }
  }
  .desc {
    color: ${palette.whiteWhite};
    font-size: 1.6rem;
    line-height: 2.2rem;
    text-align: center;
  }
  .red {
    color: ${palette.red};
    font-size: 2rem;
    font-family: SofiaProSemiBoldAz, sans-serif;
    text-align: center;
  }
`;
